.Dashboard {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-family: var(--Fonte);
  background-color: var(--BackGroundColorTelas);

}

.Dashboard p {
  font-family: var(--Fonte);
  text-align: center;
  margin-bottom: 50px;
  font-size: 18px;
}

.Dashboard img {
  max-width: 90%;
  width: 80%;
}


.ChartsPies {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
}


.ChamadosRow {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  margin-bottom: 4rem;
}

.ChamadoRowItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--Fonte);
}


.ChamadoRowQuant {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  font-family: var(--Fonte);
  cursor: pointer;
}

.ChamadoRowDesc {
  font-family: var(--Fonte);
}



.AbertoQuant {
  border: 2px solid #008ffb;
}

.ConcluidoQuant {
  border: 2px solid #00e396;
}

.CanceladoQuant {
  border: 2px solid #feb019;
}

.AndamentoQuant {
  border: 2px solid #ff4560;
}


.AbertoQuant:hover {
  background-color: #008ffb;
  color: white;
}

.ConcluidoQuant:hover {
  background-color: #00e396;
  color: white;
}

.CanceladoQuant:hover {
  background-color: #feb019;
  color: white;
}

.AndamentoQuant:hover {
  background-color: #ff4560;
  color: white;
}



.DashboardName {
  font-family: var(--Fonte);
  font-size: 2rem !important;
  margin-bottom: 1rem !important;
}



@media (max-width: 900px) {
  .ChamadoRowQuant {
    width: 2rem;
    height: 2rem;
  }

  .ChamadosRow {
    gap: .5rem;
  }

  .ChamadoRowDesc {
    font-size: 1rem;
    display: flex;

  }

  .ChartsPies {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 0;
  }
}