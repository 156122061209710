.ChamadosTable {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 15rem;
}

.AlertaTabelaVazia {
  min-height: 15rem;
}

.ChamadosHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 1.5rem;
}

.ChamadosHeader input {
  padding: 5px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: var(--Fonte);
  font-size: 12px;
  max-height: 100px;
}




.react-datepicker * {
  font-family: var(--Fonte) !important;
}

.ChamadosTableHeader,
.ChamadosBodyTableRow {
  width: 100%;
  display: grid;
  grid-template-columns: 15% 15% 35% 20% 15%;

}

.ChamadosTableHeader {
  background-color: var(--FundoEscuro);
  padding-top: 1rem;
  padding-left: 0.6rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: var(--TextoCabecalhoTabela);
}

.ChamadosTableHeader,
.ChamadosBodyTableRow {
  width: 100%;
  display: grid;
  grid-template-columns: 12% 12% 15% 32% 17% 12%;
}

.ChamadosTableHeader span {
  font-family: var(--Fonte);
  font-size: 13px;
  font-weight: 500;
  padding-right: 20px;
  margin-bottom: 10px;
  word-wrap: normal;
}

.ChamadosTableBody {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ChamadosBodyTableRow {
  font-size: 10px;

}


.ChamadosBodyTableRow span {
  font-family: var(--Fonte);
  font-size: 10px;
  padding-right: 20px;
  text-align: justify;
  padding: 0.2rem 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
  text-decoration: none;
}


.ChamadosBodyTableRow a span {
  font-family: var(--Fonte);
  font-size: 10px;
  padding-right: 20px;
  text-align: justify;
  padding: 0.2rem 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
  text-decoration: none;
  color: var(--FundoEscuro);
}

.ChamadosBodyTableRow a {
  font-family: var(--Fonte);
  font-size: 10px;
  padding-right: 20px;
  text-align: justify;
  padding: 10px 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
  text-decoration: none;
}


.ChamadosTableSpan {
  font-family: var(--Fonte);
  font-size: 10px;
  padding-right: 20px;
  text-align: justify;
  padding: 10px 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
  text-decoration: none;
  word-wrap: normal;
  flex-wrap: nowrap;
}

.BodyRowLast {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
  flex-grow: 1;
}

.ChamadosTableBody div:nth-child(even) {
  background-color: var( --LinhasTabela);

}






.ChamadosBodyTableRow span:nth-child(1) {
  font-weight: 500;
}

.Aberto {
  background-color: var(--Aberto);
}

.EmAndamento {
  background-color: var(--EmAndamento);
}

.Emandamento {
  background-color: var(--EmAndamento);
}

.Concluido {
  background-color: var(--Concluido);
}

.Cancelado {
  background-color: var(--Cancelado)
}


.StatusCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.ChamadoLinkTable {
  color: var(--AzulTexto) !important;
  cursor: pointer;
}

.ChamadoLinkTable:hover {
  color: var(--AzulTexto) !important;
  cursor: pointer;
  text-decoration: underline;
}



.PaginationDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;

}

.InputInvisible {
  background-color: var(--FundoTransparente);
  width: 4rem;
  padding: 0;
  border: none;
  outline: none;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: var(--Fonte) !important;
  font-size: 13px;
}

.BottomTableChamadosWrap {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background-color: var(--FundoTransparente);
  gap: 3rem;
  flex-grow: 1;
}

.FakeInputDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--FundoTransparente);
  border: 1px solid var(--InputBorderColor);
  border-radius: 4px;
  padding: 0.5rem;
}


.PaginationDiv div span {
  font-family: var(--Fonte) !important;
  font-size: 13px;
  margin-right: .6rem;
}

.CalendarIconRange {
  font-size: 0.9rem !important;
  color: var(--FundoEscuro);
}

.DataRangeDivNoChamados span {
  font-family: var(--Fonte) !important;
}

.react-datepicker-wrapper {
  width: auto !important;
}

.DataRangeDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.3rem;
  height: 100% !important;
  flex-grow: 2;
}

.DataRangeDivNoChamados {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.3rem;
  height: 100% !important;
  flex-grow: 2;
  margin-top: 2rem;
}


.DataRangeDiv svg {
  font-size: 1.4rem;
}

.TimeRangeSelector {
  padding: 0.5rem;
  padding-right: 0 !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: var(--Fonte);
  font-size: 12px;
  height: 100%;
  outline: none;

}



.MuiPagination-root {
  display: flex !important;
  justify-content: flex-end !important;
}

.MuiPagination-ul .MuiButtonBase-root {
  font-family: var(--Fonte) !important;
}


.ChamadosTableForMobile {
  display: none;
  min-height: 264px;
}

.ChamadosTableForMobileItemHeader {
  display: flex;
  flex-direction: row;
  align-items: center;

}

.FlexTableHeader {
  display: flex;
  flex-direction: column !important;
  gap: 0.5rem;
  width: 80% !important;
  align-items: flex-start !important;
}

.accordion-button {
  width: 15%;
}

.FlexTableHeader span {
  font-family: var(--Fonte);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
}

.FlexTableHeader span svg {
  font-size: 12px;
  margin-right: 0.2rem;
  color: var(--AzulTexto);
}

.ChamadoDescriptionHeader {
  width: 100% !important;


}

.accordion-button {
  padding-left: 7px !important;
  padding-right: 12px !important;
  font-size: 5px;
}

.AlertaNenhumChamado {
  font-family: var(--Fonte);
  font-weight: 500;
  -webkit-box-shadow: 5px 6px 7px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 6px 7px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 6px 7px -3px rgba(0, 0, 0, 0.75);
}

.ChamadosTableForMobileItemHeader span {
  font-family: var(--Fonte);
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 0.4rem;
  font-size: 13px;
}

.ChamadosTableForMobileItemHeader span svg {
  color: var(--AzulTexto);
}



@media (max-width: 800px) {


  .FakeInputDiv {
    display: none;
  }

  .PaginationDiv {
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;

  }

  .DataRangeDiv span {
    display: none;
  }

  .ChamadosTableForMobileItemHeader span {
    font-size: 10px;
  }

  .StatusCircle {
    margin-right: 5px;
  }

  .ChamadoDescriptionHeader {
    font-size: 10px;
  }

  .ChamadoLinkTable {
    padding-top: 1rem;
    float: right;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
  }

  .ChamadosTableForMobile {
    display: block;
  }

  .ChamadosTable {
    display: none;
  }

  .ChamadosHeader {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .ChamadosHeader input {
    margin-top: 10px;
  }


  .Chamados {
    padding: 1rem;
  }

  .ChamadosTable {
    padding-top: 10px;
  }
}