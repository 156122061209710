.Testes {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 3rem;
}

.ProgressDiv{
  width: 100%;
}