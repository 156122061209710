.AprovacaoPendente{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Aviso{
    font-size: 25px;  
    text-decoration: none;    
    border-radius: 10px;
    padding: 30px;
}