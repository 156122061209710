.TitleForm {
    font-size: 30px;
    font-family: var(--Fonte);
    text-decoration: none;

}

.LogoLogin {
    width: 200px;
    margin: 20px;
}

.formRowLogin {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
}

.formContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    word-wrap: break-word;
    margin: 40px;
}

.formContainer label {
    font-weight: 500;
}

.LoginForm {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 40px 60px;
    border-radius: 5px;

    -webkit-box-shadow: 0px 0px 43px -14px rgba(0, 0, 0, 0.34);
    -moz-box-shadow: 0px 0px 43px -14px rgba(0, 0, 0, 0.34);
    box-shadow: 0px 0px 43px -14px rgba(0, 0, 0, 0.34);
}




.DivLoginForm {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.DivLoginForm img {
    margin-bottom: 3rem;
}

.DivLateralLogin {
    gap: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.VideoLink {
    color: #0275d8;
    text-decoration: none;
    font-weight: 500;
    text-decoration: none;
    font-family: var(--Fonte);
}

.VideoLinkForm {
    color: #0275d8;
    text-decoration: none;
    font-weight: 500;
    text-decoration: none;
    font-family: var(--Fonte);
    display: none;
}



.DivLateralLogin img {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
}


.LinhaOu {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.LinhaOu span {
    flex-grow: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9d9696;
    font-family: var(--Fonte);
    font-size: 13px;
    font-weight: 300;
}


.Linha {
    height: 1px;
    flex-grow: 5;
    background-color: #9d9696;

}

.LoginInput {
    padding: 7px 25px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;

}


.SignInButton {
    width: 100%;
    flex-grow: 1;
    background-color: #0275d8;
    /* Green */
    color: white;
    border: none;
    cursor: pointer;
    margin-right: 5px;
    margin-left: 5px;
    border-radius: 4px;
    padding: 10px;
}

.login-with-google-btn {
    width: 100%;
    flex-grow: 1;
}


.Link {
    color: #757575;
    text-decoration: none;
    font-weight: 500;
    font-family: var(--Fonte);
}


.RegisterLink a {
    font-family: var(--Fonte);
    color: 0275d8;
    text-decoration: none;
    font-weight: 500;
}

.RegisterLink span {
    color: #757575;
    text-decoration: none;
    font-weight: 500;
}

.ForgetLink {
    color: #757575;
    text-decoration: none;
    font-weight: 500;
    font-size: 10px;
}


.Erro {
    color: red;
}



.login-with-google-btn {
    transition: background-color .3s, box-shadow .3s;

    padding: 12px 16px 12px 42px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

    color: #757575;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 11px;

}

.login-with-google-btn:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

.login-with-google-btn:active {
    background-color: #eeeeee;
}

.login-with-google-btn:focus {
    outline: none;
    box-shadow:
        0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc;
}

.login-with-google-btn:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
}




@media (max-width: 900px) {

    .DivLateralLogin {
        display: none;
    }

    .LoginForm {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding: 20px 40px;
        border-radius: 5px;
        width: 100%;
        box-shadow: none;
    }

    .formContainer {
        margin: 0;
    }

    .VideoLinkForm {
        margin-top: 3rem;
        display: block;
        
    }
}