.Brand {
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;

}

.smNavLink {
  font-family: var(--Fonte);
  font-weight: 500;
}

.ProfileNavLink {
  color: white;
  text-decoration: none;
  font-family: var(--Fonte);
}


.profileDropdown {
  margin-right: 20px;
}

.ProfileNavLink svg {
  margin-right: 4px;
  margin-bottom: 2px;
}

.ProfileNavLink:hover {
  color: white;
  text-decoration: none;
  font-family: var(--Fonte);

}

.Notification {
  background-color: transparent;
}

.Notification svg {
  color: white;

}

.Brand a {
  color: white;

  font-family: var(--Fonte);
  text-decoration: none;
}



.Backup {
  display: none;
}

.ulMenor {
  display: none;
}

.ulGrande {
  display: block;
}

.ulGrande {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
  display: flex;
  justify-content: flex-end;
}

.nav-link {
  font-size: 13px !important;
}

#navBarResponsive {
  padding: 5px 0px !important;
}

.SerchInput {
  height: 80% !important;
}

.buscaForm {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.SerachButtonNavbar {
  height: 50% !important;
}


#sidebarOffCanvas {
  background-color: var(--FundoEscuro);
  max-width: 85%;
  -webkit-box-shadow: 6px 4px 33px -17px rgba(0, 0, 0, 1);
  -moz-box-shadow: 6px 4px 33px -17px rgba(0, 0, 0, 1);
  box-shadow: 6px 4px 33px -17px rgba(0, 0, 0, 1);

}


#SmDivNavBarID {
  -webkit-animation: fadein 1s;
  -moz-animation: fadein 1s;
  -ms-animation: fadein 1s;
  -o-animation: fadein 1s;
  animation: fadein 1s;
}


@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}



.LogoNavBar {
  width: 120px;
  margin: 0;
}

.dropDownLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #333;
  font-family: var(--Fonte);
  text-decoration: none;
  font-size: 12px;
  padding: 0.2rem 0.6rem;
  cursor: pointer;
  min-width: none !important;
  max-width: none !important;
  width: 100%;
}

.dropdown-menu{
  max-width: none !important;
  width: 12rem !important;
}


.dropDownLink:hover {
  background-color: RGBA(51, 51, 51, 0.24);
}

.IconeEscuro {
  color: #333;
}

.ProfileNavLinkTitle {
  color: white;
  font-family: var(--Fonte);
  text-decoration: none;
}


.ProfileNavLinkTitle:hover {
  color: white;
  font-family: var(--Fonte);
  text-decoration: none;
}

.LogouButtonNavSm {
  display: none;
  position: fixed;
  bottom: 0;
  font-family: var(--Fonte);

}

#LogouButtonNavSm {
  display: none;
  position: fixed;
  bottom: 0;
  font-family: var(--Fonte);
  font-size: 25px;
  font-weight: 800;

}

.offCanvasBrand {
  color: white;
  text-decoration: none;
  font-family: var(--Fonte);
}

.offCanvasBrand:hover {
  color: white;
  text-decoration: none;
  font-family: var(--Fonte);
}

.dropDownLink svg {
  margin-right: 3px;
}

.dropDownLink:hover {
  color: #333;
}

.UserPhoto {
  border-radius: 50%;
}


.SmDivNavBar {
  display: none;
}

.part {
  width: 15px;
  height: 2px;
  background-color: white;
  margin: 1px;
  border-radius: 10px;
}

.LogoAndCollpse {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.Hamburguer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.ProfileUserPhotoNav{
  width: 30px;
  margin: 0px;
  padding: 0px;
  border-radius: 50%;
 
}

.ProfilePhoto{
  width: 20px;
}

.Hamburguer:hover {
  background-color: gray;
  border-radius: 50%;

}

.ulGrande li a:hover:not(.active) {
  background-color: #111;
}

.active {
  background-color: #aa3604;
  cursor: pointer;

}


.Loading {
  margin: 40px;
}

.BrandName {
  flex-grow: 8;
}

.UserPhoto {
  width: 10px;
  margin: 0px;
  padding: 0px;
}

.BodyNavSm {
  display: none;
}


@media (max-width: 800px) {
  #LogouButtonNavSm {
    display: block;
  }

  .LogouButtonNavSm {
    display: block;
  }
}



@media (max-width: 750px) {


  .SmDivNavBar {
    display: flex;
    flex-direction: column;
    font-family: var(--Fonte);
    position: relative;
  }


  .Hamburguer {
    display: none;
  }



  .SmDivNavBar a {
    padding: 10px;
    color: white;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-family: var(--Fonte);
    margin-top: 5px;
    width: 100%;
  }

  .SmDivNavBar a .SmNavBarText {
    flex-grow: 5;
  }

  .SmDivNavBar svg {
    margin-right: 7px;
  }

  .SmDivNavBar ul {
    list-style: none;
    padding: 0px;
    width: 100%;
  }

  .SmUl li {
    display: flex;
    align-items: center;
  }

  .SmUl li a span {
    font-family: var(--Fonte);
    font-weight: 500;
  }




  .SmDivNavBar {
    display: block;
  }

  .profileDropdown {
    display: none;
  }

  .BrandName {
    flex-grow: 8;
  }


  .BrandName h1 {
    display: none;
  }

  .ulMenor {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333;
    display: flex;
  }

  .BrandNameMenor {
    flex-grow: 8;
  }


  .BrandNameMenor h1 {
    display: block;
  }





}