.VideoContainter {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}



.VideoPlayer {
    width: 60%;
    border-radius: 10px;
    overflow: hidden;
}


.BackLink {
    color: #0275d8;
    text-decoration: none;
    font-weight: 500;
    text-decoration: none;
    font-size: 1.25rem;

}



@media (max-width: 900px) {

    .VideoPlayer {
        width: 95%;
        border-radius: 10px;
        overflow: hidden;
    }


   
    

}