.AbrirChamado {
  height: 95vh;
  padding: 2rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 500px;
  overflow-y: scroll;

}

.AbrirChamado h2 {
  font-family: var(--Fonte);
}

.FormWrapper {
  display: flex;
  flex-direction: row;
}


.DefinicaoSolicitante {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.DefinicaoSolicitante>span {
  font-family: var(--Fonte);
  font-size: 1.2rem;
}


.FliesToUpload {
  margin-top: 0.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.2rem;
}

.FliesToUpload span {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: var(--Fonte);
  font-size: 0.7rem;
}

.FliesToUpload svg {
  font-size: 0.7rem;
}

.CancellUpload {
  cursor: pointer;
  margin-left: 0.2rem;
}

.OpcoesSolicitante {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1rem;
}


.OpcoesSolicitante button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--AzulTexto);
  padding: 5px 25px;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: var(--Fonte);
  font-size: 12px;
  outline: none;
  -webkit-box-shadow: 10px 11px 10px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 11px 10px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 11px 10px -10px rgba(0, 0, 0, 0.75);
  margin-bottom: 1rem;
  height: 1.7rem;
}

.OpcoesSolicitante button:active {
  margin-bottom: 0.8rem;
}


.OpcoesSolicitante button span {
  font-family: var(--Fonte);
  font-size: 0.8rem;
  color: white;
}

.OpcoesSolicitante button svg {
  font-family: var(--Fonte);
  font-size: 0.8rem;
  color: white;
}


.TabChamadoTituloSolicitante {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.TabChamadoTituloSolicitante span {
  font-family: var(--Fonte);
  font-size: 1.2rem;
}

.TabChamadoTituloSolicitante span:first-child {
  width: 1rem;
  height: 1rem;
  background-color: var(--AzulTexto);
  border-radius: 50%;
  margin-right: 0.7rem;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
}



.tituloAberturaDeChamado {
  margin-bottom: 1rem;
}






.AnexoLabel input {
  display: none;
  cursor: pointer;
}

.AnexoLabel {
  cursor: pointer;
  text-decoration: none;
  color: var(--AzulTexto);
  margin-top: 5px;
  font-family: var(--Fonte);
}

s .AnexoLabel span {
  font-size: 1rem;
}

.AnexoLabel svg {
  font-size: 1rem;
}


.Anexo {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
}


.AbrirChamado form {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.InfoPendente {
  display: flex;
  flex-direction: column;
}

.InfoPendente span {

  font-family: var(--Fonte);
  display: flex;
  align-items: center;
  font-size: 14px;
}

.InfoPendenteHeader {
  display: flex;

  margin-bottom: 0.7rem;
}

.InfoPendente span svg {

  margin-right: 0.6rem;
}

.CheckedInfoPendente {
  color: #5cb85c;
}

.UncheckedInfoPendente {
  color: #d9534f;
}

.SubmitFormAddChamado {
  padding: 1rem;
  margin: 1rem;
  height: 100px;
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
}

.AbrirChamadoForm input,
textarea {
  padding: 7px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: var(--Fonte);
  font-size: 12px;
  max-height: 100px;
  width: 100%;
}

.AbrirChamadoForm textarea {
  padding: 7px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: var(--Fonte);
  font-size: 12px;
  max-height: 100px; 
  width: 100%;
}



.LabelSolicitadoPara span {
  color: #f0ad4e;
}

.LabelSolicitadoPara svg {
  color: #f1981c !important;
}


.SolicitadoParaBodyRow {
  margin-top: 1rem;
  border-top: 1px solid var(--AzulTexto);
}

.AbrirChamadoForm input:disabled {

  background-color: white;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.AbrirChamadoForm textarea {
  font-size: 11px;
  font-family: var(--Fonte);
}


.ModalHeader {
  font-family: var(--Fonte);
  font-size: 17px;

}

.AbrirChamadoForm label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: var(--AzulTexto);
  font-family: var(--Fonte);
  font-weight: 400;
  gap: 3px;
  margin-bottom: 5px;
  margin-top: 10px;
  font-size: 12px;
}


.AbrirChamadoForm label span {
  font-family: var(--Fonte);

}

.AbrirChamadoForm label svg {
  color: var(--AzulTexto);
}


.DoubleColumnChamado {
  margin: 0;
  display: grid;
  grid-template-columns: 49.5% 49.5%;
  gap: 1%;
}

.SelectReactChamados {
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: var(--Fonte);
  font-size: 12px;
}




.AlertaLei {
  text-align: justify;
  font-size: 12px;
}


.AbrirChamadoButton {
  background-color: var(--AzulTexto) !important;
  width: 250px;
  margin-bottom: 30px;
}




.modal-header {
  padding: 10px 16px !important;
  padding-bottom: 0px !important;
}


.TextoBodyRowTerceiros {
  font-size: 14px;
  font-family: var(--Fonte);
}

.BodyRow {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  font-size: 12px;
  margin-bottom: 4px;
  align-items: center;
  flex-wrap: wrap;
  word-wrap: normal
}

.BodyRow .progress {
 width: 100% !important;
 font-family: var(--Fonte) !important;
}

.progress-bar{
  font-family: var(--Fonte) !important;
}

.BodyRowProgress {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  font-size: 12px;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
  word-wrap: normal
}

.BodyRow svg {
  margin-right: 5px;
  color: var(--AzulTexto);
}

.TituloBodyRow {
  margin-right: 5px;
  color: var(--AzulTexto);
  font-family: var(--Fonte);
}

.TextoBodyRow {
  font-family: var(--Fonte);
  color: rgb(54, 54, 54);
  text-align: justify;
  word-break: break-all;
}

.ListGroupCHamados {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  margin-top: 2rem;
}

.CategoriasListGroup {
  margin-right: 1rem;
}

.SubCategoriaInfo {
  display: none;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: .5rem;
}

.SubCategoriaInfo span {
  font-family: var(--Fonte);
}

.SubCategoriaInfo svg {
  margin-left: 1rem;
}


.CategoriasListGroup span,
.SubCategoriasListGroup span {
  cursor: pointer;
}



.CategoriaListItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.CategoriaListItem span {
  flex-grow: 1;
  font-family: var(--Fonte);
  font-size: 12px;
}


.CategoriaListItem svg {
  float: right;
  margin-left: .6rem;
}


.CategoriasListGroup .list-group {
  min-width: 300px !important;
}

.SubCategoriasListGroup .list-group {
  min-width: 300px !important;
}





@media (max-width: 900px) {

  .InfoPendente,
  .SubmitFormAddChamado {
    display: none;
  }

  .AbrirChamado form {
    width: 100%;
  }

  .AbrirChamado {
    padding: 1rem;
  }

  .SelectReactChamados {
    font-size: 10px;
  }

  .AlertaLei {
    font-size: 9px;
  }



  .ListGroupCHamados {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    margin-top: 2rem;
  }

  .CategoriasListGroup {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .SubCategoriaInfo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: .5rem;
  }

  .DefinicaoSolicitante {
    height: 100%;
    justify-content: center;
  }

  .OpcoesSolicitante {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }


  .TabChamadoTituloSolicitante span {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: var(--Fonte);
    font-size: 0.9rem !important;
  }

  .TabChamadoTituloSolicitante span:first-child {
    display: none;
  }


}








/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}