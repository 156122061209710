.Feedback {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 3rem;
  gap: 2rem;
}

.Feedback h1,h2{
  font-family: var(--Fonte);
  text-align: center;
}


.Feedback h2{
  font-family: var(--Fonte);
  text-align: center;
}

@media (max-width: 800px) {}